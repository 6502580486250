$font-family_1: poppins, sans-serif;
$midgrey_80: rgba(100, 100, 100, 0.8);

:root {
  --color_accent-foreground: #fa41ff;
  --color_black: #0a0a0a;
  --color_white: #fff;
  --color_white-20: rgba(255, 255, 255, 0.2);
  --color_white-40: rgba(255, 255, 255, 0.4);

  --layout_width: 70rem;
}

html {
  font-size: 20px;
  background-color: var(--color_black);
  color: var(--color_white);
}

body {
  margin: 0;
  line-height: 1.33;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family_1;
  font-weight: 200;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}
header {
  background-image: url(../public/assets/granite.webp);
  position: sticky;
  top: 0;
  z-index: 100;
  filter: drop-shadow(0 0.25rem 0.5rem var(--color_black));
}
.header-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: var(--layout_width);
  max-width: 90vw;
  margin: auto;

  h1 {
    padding-left: 1em;
    background-image: url(../public/assets/thumbprint.webp);
    background-size: contain;
    background-position: 0 50%;
    background-repeat: no-repeat;
    line-height: 1;
    user-select: none;
    margin: 0;
    transition: filter 0.2s;

    &:hover {
      filter: drop-shadow(0 0.06125rem 0.5rem var(--color_accent-foreground));
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
.region-select {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: flex-end;
  align-items: center;
  select {
    border: 0;
    outline: none;
    appearance: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-family: $font-family_1;
    font-weight: 600;
    background-color: $midgrey_80;
    color: var(--color_white);
    cursor: pointer;
    &:hover {
      box-shadow: 0 0.06125rem 1rem var(--color_accent-foreground);
      background-color: var(--color_accent-foreground);
    }
    &:active {
      box-shadow: 0 0.06125rem 1rem var(--color_accent-foreground);
      background-color: var(--color_accent-foreground);
    }
    option {
      background-color: var(--color_white);
      color: var(--color_black);
      font-family: $font-family_1;
      font-weight: 600;
    }
    &:focus {
      option {
        &:checked {
          display: none;
        }
      }
    }
  }
}
main {
  padding: 2rem;
  width: var(--layout_width);
  max-width: 90vw;
  margin: auto;
  h3 {
    font-size: 2rem;
    margin: 1em 0 1rem 1rem;
  }
}
.season {
  display: grid;
  grid-template-columns: 18rem auto;
  position: relative;
  z-index: 0;
  &::before {
    content: '';
    position: absolute;
    width: 2rem;
    height: 100%;
    top: 0;
    left: 18rem;
    z-index: 10;
    background-image: linear-gradient(
      to right,
      var(--color_black),
      transparent
    );
    pointer-events: none;
  }
  &::after {
    content: '';
    position: absolute;
    width: 4rem;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      transparent,
      var(--color_black)
    );
    pointer-events: none;
  }
  img {
    max-width: 100%;
  }
}
.season-overview {
  grid-column-start: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-image: url(../public/assets/granite.webp);
  gap: 1rem;
  border-radius: 0.5rem 0 0 0.5rem;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.pricing {
  margin: 0 0 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;

  h4 {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    margin-bottom: -0.5rem;
    text-align: center;
    color: var(--color_white-40);
  }
  .price {
    font-size: 3em;
  }
}
.button {
  color: var(--color_white);
  border: 0;
  border-radius: 0.25rem;
  outline: none;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  background-color: $midgrey_80;
  transition: 0.2s;
  cursor: pointer;
  font-family: $font-family_1;
  font-weight: 600;
  text-decoration: none;

  &:hover,
  &.active {
    background-color: var(--color_accent-foreground);
    box-shadow: 0 0.06125rem 1rem var(--color_accent-foreground);
  }
}
.season-episodes {
  grid-column: 2;
  overflow-x: auto;
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  scroll-behavior: smooth;
  padding: 1rem 2rem 2rem;
  user-select: none;
  scrollbar-color: $midgrey_80 transparent;
  background-image: linear-gradient(to right, #242424, transparent);
  &:hover {
    scrollbar-color: var(--color_accent-foreground) transparent;
  }
}
::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  transition: 0.2s;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: $midgrey_80;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--color_accent-foreground);
}
.episode {
  width: 14rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 12rem;
    height: 16rem;
    object-fit: cover;
    transition: 0.2s;
    position: absolute;
    outline: 0.2rem solid var(--color_white-20);
    outline-offset: -0.2rem;
    filter: drop-shadow(0 0.25rem 0.5rem var(--color_black));
    border-radius: 0.5rem;
    bottom: 2rem;
  }
  h4 {
    position: absolute;
    bottom: 0;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .synopsis {
    position: absolute;
    width: 30rem;
    height: 20rem;
    top: 1rem;
    left: 0;
    z-index: 100;
    background-color: var(--color_black);
    cursor: default;
    padding: 1rem;
    overflow-y: auto;
    font-size: 0.8rem;
    outline: 0.2rem solid var(--color_white-20);
    box-shadow: 0 0.06125rem 2rem var(--color_accent-foreground);
    display: flex;
    border-radius: 0.25rem;
    box-sizing: border-box;
    flex-direction: column;

    // overlay to prevent opening multiple
    &::before {
      content: '';
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: all;
    }
    h5 {
      font-size: 1.5rem;
      margin: 0;
    }
    p {
      margin: 1rem auto;
      line-height: 1.33;
      text-align: justify;
    }
  }
  // need to right align the last one
  &:last-of-type {
    .synopsis {
      left: auto;
      right: 0;
    }
  }
  .close-button {
    border-radius: 100%;
    padding: 0;
    display: flex;
    width: 2rem;
    font-size: 2rem;
    height: 2rem;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0.5rem;
    right: 0.5rem;
  }
}
.episode.clickable {
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.05);
      transform-origin: 50% 100%;
      outline-color: var(--color_accent-foreground);
      box-shadow: 0 0.06125rem 2rem var(--color_accent-foreground);
    }
  }
}
